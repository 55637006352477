import React from 'react'
import { Link } from 'gatsby'
import { MdBook } from 'react-icons/md';

import './BookADemoLink.scss';

class BookADemoLink extends React.Component {
  render() {
    return (
      <Link
        className='book-a-demo'
        to='/book-a-demo'
      >
    		Book a<br/> demo
    		<MdBook />
    	</Link>
    )
  }
}

export default BookADemoLink;
