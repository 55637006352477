import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import './Footer.scss'

class Footer extends React.Component {
  drawNavLinks(navLinks) {
    const links = navLinks.map(link => {
      return (
        <li key={link.wordpress_id}>
          <Link
            to={link.object_slug === 'home' ? '/' : `/${link.object_slug}/`}
          >
            {link.title}
          </Link>
        </li>
      )
    })

    return <ul className="footer-links">{links}</ul>
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query FooterQuery {
            allWordpressWpApiMenusMenusItems {
              edges {
                node {
                  name
                  slug
                  count
                  items {
                    order
                    wordpress_id
                    title
                    object_slug
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className="footer-wrapper">
            <div className="container">
              {this.drawNavLinks(
                data.allWordpressWpApiMenusMenusItems.edges.find(
                  e => e.node.slug === 'footer-menu'
                ).node.items
              )}
              <p>
                © {new Date().getFullYear()} Access Control UK. All rights
                reserved.
              </p>
            </div>
          </div>
        )}
      />
    )
  }
}

export default Footer
