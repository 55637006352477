import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import './Navbar.scss'

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { mobileMenuVisible: false }
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this)
  }

  drawNavLinks(navLinks) {
    const links = navLinks.map(link => {
      if (link.wordpress_children) {
        let hasActiveChild

        if (typeof window !== 'undefined' && window.location) {
          hasActiveChild = link.wordpress_children.find(
            c => `/${c.object_slug}/` === window.location.pathname
          )
        }

        return (
          <div
            className={`navbar-item has-dropdown is-hoverable ${
              hasActiveChild ? 'active' : ''
            }`}
            key={link.wordpress_id}
          >
            <Link className="navbar-link" to={`/${link.object_slug}/`}>
              {link.title}
            </Link>
            <div className="navbar-dropdown">
              {this.drawNavLinks(link.wordpress_children)}
            </div>
          </div>
        )
      } else {
        return (
          <Link
            className="navbar-item"
            activeClassName="active"
            to={link.object_slug === 'home' ? '/' : `/${link.object_slug}/`}
            key={link.wordpress_id}
          >
            {link.title}
          </Link>
        )
      }
    })

    return links
  }

  toggleMobileMenu() {
    this.setState(state => ({
      mobileMenuVisible: !state.mobileMenuVisible,
    }))
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query MenuQuery {
            allWordpressWpApiMenusMenusItems {
              edges {
                node {
                  name
                  slug
                  count
                  items {
                    order
                    wordpress_id
                    title
                    object_slug
                    wordpress_children {
                      wordpress_id
                      title
                      object_slug
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <nav className="navbar">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item">
                <h1>Access Control Scotland</h1>
              </Link>
              <p>
                Access Control Systems, Door Access Control, Gates, Carpark
                Barriers, Turnstiles &amp; Biometrics Access Control Systems.
              </p>
              <div className="telephone-wrapper columns is-mobile is-vcentered">
                <div className="column is-8">
                  <p>+44 (0)1413 088695</p>
                </div>
                <div className="column is-4">
                  <a
                    role="button"
                    className={`navbar-burger burger ${
                      this.state.mobileMenuVisible ? 'is-active' : ''
                    }`}
                    aria-label="menu"
                    aria-expanded="false"
                    data-target="navbarBasicExample"
                    onClick={this.toggleMobileMenu}
                  >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                  </a>
                </div>
              </div>
            </div>
            <div
              className={`navbar-menu ${
                this.state.mobileMenuVisible ? 'is-active' : ''
              }`}
            >
              <div className="navbar-end">
                {this.drawNavLinks(
                  data.allWordpressWpApiMenusMenusItems.edges.find(
                    e => e.node.slug === 'primary-menu'
                  ).node.items
                )}
              </div>
            </div>
          </nav>
        )}
      />
    )
  }
}

export default Navbar
