import React from 'react'
import Helmet from 'react-helmet'

import Navbar from './Navbar/Navbar'
import Footer from './Footer/Footer'
import BookADemoLink from './BookADemoLink/BookADemoLink'
import '../styles/all.scss'

const TemplateWrapper = ({ children }) => (
  <div className="site-wrapper">
    <Helmet title="Home | Gatsby + WordPress" />
    <div className='container'>
      <Navbar />
      <div>{children}</div>
    </div>
    <Footer />
    <BookADemoLink />
  </div>
)

export default TemplateWrapper
